import React from 'react'
import useBreakpoint from '../../customHooks/useBreakpoint'
import WhatWeDoQuote from '../WhatWeDoQuote'
import Rounded1 from '../../assets/img/what-we-do/what-we-do-1.svg'
import Rounded2 from '../../assets/img/what-we-do/what-we-do-2.svg'
import Rounded3 from '../../assets/img/what-we-do/what-we-do-3.svg'
import Rounded4 from '../../assets/img/what-we-do/what-we-do-4.svg'
import Arrows from '../../assets/img/what-we-do-arrows.svg'
import Check from '../../assets/img/rounded-check-icon.svg'

const steps = [
  {
    title: 'Design Workshops',
    description: 'UX research, market research',
    image1: Rounded1,
    alt1: '',
    image2: Arrows,
    alt2: ''
  },
  {
    title: 'Research',
    description: 'Sprints and Design Thinking',
    image1: Rounded2,
    alt1: '',
    image2: Arrows,
    alt2: ''
  },
  {
    title: 'UX Design',
    description: 'Wireframing, rapid prototyping & testing',
    image1: Rounded3,
    alt1: '',
    image2: Arrows,
    alt2: ''
  },
  {
    title: 'UI Design',
    description: 'Visual Design, Interactions, Functional Prototypes',
    image1: Rounded4,
    alt1: '',
    image2: Check,
    alt2: ''
  }
]

const WhatWeDoPageSection1 = ({ data }) => {
  const isMobile = useBreakpoint('sm', false)

  if (!data) {
    return ''
  }

  return (
    <>
      <div
        className='content-container' style={{ height: isMobile ? 'unset' : '100vh', minHeight: '800px' }}>
        <div style={{ width: '100%', maxWidth: 1200 }}>
          {data.heading &&
            <div className='quint-heading-big-2 font-main color-primary' style={{ marginBottom: 32 }}>
              {data.heading}
            </div>}
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 48,
            flexDirection: isMobile ? 'column' : 'row'
          }}>
            {data.subtitle1 &&
              <div
                className='quint-subtitle font-main color-primary-dark'
                style={{ flex: 1, maxWidth: '550px', paddingRight: isMobile ? '0' : '16px', marginBottom: isMobile ? '16px' : '0', lineHeight: 1, fontWeight: 'normal' }}>
                {data.subtitle1}
              </div>}
            {data.subtitle2 &&
              <div
                className='quint-subtitle font-main color-primary-dark'
                style={{ flex: 1, maxWidth: '550px', paddingLeft: isMobile ? '0' : '16px', lineHeight: 1, fontWeight: 'normal' }}>
                {data.subtitle2}
              </div>}
          </div>
          <div
            className='content-container-horizontal padding-0'
            style={{
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: 48,
              flexDirection: isMobile ? 'column' : 'row'
            }}>
            {steps && steps.map((step, index) => {
              const lastChild = index === steps.length - 1
              return (
                <div
                  style={{
                    border: '1px solid #B8C3F2',
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: isMobile ? 'flex-start' : 'space-between',
                    alignItems: isMobile ? 'center' : 'flex-start',
                    height: isMobile ? '224px' : '250px',
                    width: isMobile ? '214px' : '268px',
                    padding: '16px',
                    marginBottom: isMobile ? '8px' : '0'
                  }} key={index}>
                  {!isMobile &&
                    <div style={{
                      position: 'relative',
                      height: 100,
                      width: 100,
                    }}>
                      <img src={step.image1} alt='' style={{ height: 100, width: 100 }} />
                      <img src={step.image2} alt='' style={{ position: 'absolute', bottom: 39, left: 70 }} />
                    </div>}
                  <div className='quint-subtitle font-main color-primary-dark' style={{ fontWeight: 'bold', marginBottom: isMobile ? '8px' : '0' }}>
                    {step.title}
                  </div>
                  <div
                    className='quint-body font-main color-primary-dark'
                    style={{ fontWeight: 'normal', maxWidth: '200px', marginBottom: isMobile ? '8px' : '0' }}>
                    {step.description}
                  </div>
                  {isMobile &&
                    <div style={{
                      position: 'relative',
                      height: 70,
                      width: 70
                    }}>
                      <img src={step.image1} alt='' style={{ height: 70, width: 70 }} />
                      <img
                        src={step.image2} alt='' style={
                          lastChild
                            ? {
                              height: 16,
                              position: 'absolute',
                              left: '28px',
                              bottom: -4
                            }
                            : {
                              height: 16,
                              width: 55,
                              position: 'absolute',
                              transform: 'rotate(90deg)',
                              transformOrigin: 'left',
                              left: '35px',
                              bottom: '2px'
                        }
                        } />
                    </div>}
                </div>
              )
            })}
          </div>
          {data.quote && data.quote.document && data.quote.document && data.quote.document.data &&
            data.quote.document.data.person && data.quote.document.data.person.document &&
            data.quote.document.data.person.document && data.quote.document.data.person.document.data &&
              <WhatWeDoQuote
                style={isMobile ? { marginBottom: '64px' } : {}}
                image={data.quote.document.data.person.document.data.first_image}
                content={data.quote.document.data.quote_content}
                name={data.quote.document.data.person.document.data.name}
                position={data.quote.document.data.person.document.data.role} />}
        </div>
      </div>
      <div
        className='content-container background-color-primary-light padding-0' style={{
          height: '16px',
        }} />
    </>
  )
}

export default WhatWeDoPageSection1

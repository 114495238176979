import React from 'react'
import ArrowDown from '../../assets/img/arrow-down.svg'
import useBreakpoint from '../../customHooks/useBreakpoint'

const WhatWeDoPageSection0 = ({ data }) => {
  const isMobile = useBreakpoint('sm', false)

  if (!data) {
    return ''
  }

  return (
    <>
      <div
        className='content-container' style={{
          height: '100vh',
          minHeight: '700px',
          position: 'relative',
          overflowX: 'hidden',
          backgroundImage: data.background_image ? `url(${data.background_image.url})` : '',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: isMobile ? '75%' : '45%'
        }}>
        {data.heading &&
          <h2
            className='quint-heading-big font-main color-primary text-center'
            style={{
              height: 'fit-content',
              position: 'absolute',
              margin: 'auto',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }}>
            {data.heading}
          </h2>}
        <div style={{ position: 'absolute', bottom: '0', display: 'flex', flexDirection: 'column' }}>
          <span className='quint-body font-main color-primary-dark' style={{ marginBottom: '32px' }}>
            scroll for more
          </span>
          <img
            className='bounce-simple animated medium infinite'
            src={ArrowDown}
            alt=''
            style={{ marginBottom: '16px', height: 34 }} />
        </div>
      </div>
      <div
        className='content-container background-color-primary-light padding-0' style={{ height: '16px' }} />
    </>
  )
}

export default WhatWeDoPageSection0

import React from 'react'
import useBreakpoint from '../../customHooks/useBreakpoint'
import WhatWeDoQuote from '../WhatWeDoQuote'
import Arrows from '../../assets/img/what-we-do-arrows.svg'

const steps = ['Handoff', 'Staging', 'Testing', 'Production']

const WhatWeDoPageSection2 = ({ data }) => {
  // const [selected, setSelected] = useState(0)
  const isMobile = useBreakpoint('sm', false)

  if (!data) {
    return ''
  }

  // function setSelectedCategory(detail) {
  //   if (data.technologies) {
  //     const index = data.technologies.findIndex((obj) => {
  //       return obj.title === detail
  //     })
  //     if (index >= 0) {
  //       setSelected(index)
  //     }
  //   }
  //   return 0
  // }

  return (
    <>
      <div
        className='content-container padding-0' style={{ height: isMobile ? 'unset' : '100vh', minHeight: 950 }}>
        <div style={{ width: '100%', maxWidth: 1200 }}>
          {data.heading &&
            <div className='quint-heading-big-2 font-main color-primary' style={{ padding: '16px 16px 0', marginBottom: 32 }}>
              {data.heading}
            </div>}
          <div style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: isMobile ? 32 : 48,
            padding: '0 16px'
          }}>
            {data.subtitle1 &&
              <div
                className='quint-subtitle font-main color-primary-dark'
                style={{ flex: 1, maxWidth: 550, paddingRight: isMobile ? '0' : '16px', lineHeight: 1, fontWeight: 'normal', marginBottom: isMobile ? '16px' : '0' }}>
                {data.subtitle1}
              </div>}
            {data.subtitle2 &&
              <div
                className='quint-subtitle font-main color-primary-dark'
                style={{ flex: 1, maxWidth: 550, paddingLeft: isMobile ? '0' : '16px', lineHeight: 1, fontWeight: 'normal' }}>
                {data.subtitle2}
              </div>}
          </div>
          <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', marginBottom: isMobile ? 16 : 64, padding: '0 16px' }}>
            {steps.map((step, index) => {
              const lastChild = index === steps.length - 1
              return (
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }} key={index}>
                  <div
                    className='quint-heading color-primary-medium font-main'
                    style={{ marginRight: isMobile ? 0 : 32, marginBottom: isMobile ? 32 : 0, paddingTop: 2 }}>
                    {step}
                  </div>
                  {!lastChild &&
                    <img
                      src={Arrows}
                      alt=''
                      style={isMobile
                        ? { height: 16, outline: 'none', transform: 'rotate(90deg)', marginBottom: 32 }
                        : { marginRight: 32 }} />}
                </div>
              )
            })}
          </div>
          {/* <LoadableQuintSidebar */}
          {/*  centered noTitle topBarNoMargin noScroll */}
          {/*  style={{ height: 'fit-content', width: '100%', marginBottom: 32 }}> */}
          {/*  {data.technologies && data.technologies.map((category, index) => { */}
          {/*    return ( */}
          {/*      <span key={index} id={category.title} slot='navBar'> */}
          {/*        {category.title} */}
          {/*      </span> */}
          {/*    ) */}
          {/*  })} */}
          {/*  <div */}
          {/*    slot='content' style={{ */}
          {/*      display: 'flex', */}
          {/*      justifyContent: 'center', */}
          {/*      alignItems: 'center', */}
          {/*      height: '100%', */}
          {/*      width: '100%', */}
          {/*      padding: 16 */}
          {/*    }}> */}
          {/*    <img */}
          {/*      style={isMobile ? { width: '100%', padding: 16 } : {}} */}
          {/*      src={data.technologies && data.technologies[selected] && data.technologies[selected].icon */}
          {/*        ? data.technologies[selected].icon.url : ''} */}
          {/*      alt='' /> */}
          {/*  </div> */}
          {/* </LoadableQuintSidebar> */}
          {data.quote && data.quote.document && data.quote.document && data.quote.document.data &&
            data.quote.document.data.person && data.quote.document.data.person.document &&
            data.quote.document.data.person.document && data.quote.document.data.person.document.data &&
              <WhatWeDoQuote
                style={isMobile ? { padding: '16px', marginBottom: '64px' } : {}}
                image={data.quote.document.data.person.document.data.first_image}
                content={data.quote.document.data.quote_content}
                name={data.quote.document.data.person.document.data.name}
                position={data.quote.document.data.person.document.data.role} />}
        </div>
      </div>
      <div
        className='content-container background-color-primary-light padding-0' style={{ height: 16 }} />
    </>
  )
}

export default WhatWeDoPageSection2

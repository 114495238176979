import React from 'react'
import useBreakpoint from '../../customHooks/useBreakpoint'
import Quotes from '../../assets/img/quotes.svg'

const WhatWeDoQuote = ({ image, content, name, position, style, ...rest }) => {
  const isMobile = useBreakpoint('sm', false)

  return (
    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', ...style }} {...rest}>
      <div style={isMobile
        ? { display: 'flex', marginBottom: '24px', padding: '0 16px' }
        : {}}>
        {isMobile
          ? <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginBottom: '8px' }}>
            <img src={Quotes} alt='' />
          </div>
          : <img src={Quotes} alt='' />}
        {image &&
          <img
            src={image.url} alt={image.alt} style={{
              margin: '0 32px',
              borderRadius: '50%',
              height: '80px',
              width: '80px',
              border: '1px solid #99A7FF',
              objectFit: 'cover'
            }} />}
        {isMobile && <div style={{ flex: 1 }} />}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: isMobile ? 'unset' : '600px' }}>
        {content &&
          <div
            className='quint-subtitle color-primary-dark font-main'
            style={{
              minHeight: '80px',
              fontWeight: 'normal',
              marginBottom: '16px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            {content}
          </div>}
        <div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
          {name && position &&
            <div className='quint-subtitle color-primary-dark font-main' style={{ fontWeight: '500' }}>
              -{name}, {position}
            </div>}
          {name && !position && <div className='quint-subtitle color-primary-dark font-main' style={{ fontWeight: '500' }}>
            -{name}
          </div>}
        </div>
      </div>
    </div>
  )
}

export default WhatWeDoQuote

import React from 'react'
import useBreakpoint from '../../customHooks/useBreakpoint'
import WhatWeDoQuote from '../WhatWeDoQuote'

const WhatWeDoPageSection4 = ({ data }) => {
  const isMobile = useBreakpoint('sm', false)

  if (!data) {
    return ''
  }

  return (
    <>
      <div
        className='content-container background-color-primary-light' style={{
          padding: isMobile ? '64px 16px' : '156px 0'
        }}>
        <div style={isMobile ? { display: 'contents' } : { maxWidth: 1200 }}>
          {data.quote && data.quote.document && data.quote.document && data.quote.document.data &&
          data.quote.document.data.person && data.quote.document.data.person.document &&
          data.quote.document.data.person.document && data.quote.document.data.person.document.data &&
            <WhatWeDoQuote
              image={data.quote.document.data.person.document.data.first_image}
              content={data.quote.document.data.quote_content}
              name={data.quote.document.data.person.document.data.name}
              position={data.quote.document.data.person.document.data.role}
              style={{ marginBottom: 16 }}
            />}
          {/* {data.button && */}
          {/*  <AniLink */}
          {/*    cover bg='#25317B' to='/who-we-are' */}
          {/*    style={{ */}
          {/*      textDecoration: 'inherit', */}
          {/*      marginLeft: isMobile ? 0 : 150, */}
          {/*      alignSelf: 'center' */}
          {/*    }}> */}
          {/*    /!* <LoadableQuintButton *!/ */}
          {/*    /!*  border={false} *!/ */}
          {/*    /!*  background='#25317B' *!/ */}
          {/*    /!*  textColor='white' *!/ */}
          {/*    /!*  rounded='pill-small' *!/ */}
          {/*    /!*  paddingX='32px' *!/ */}
          {/*    /!*  width='fit-content' *!/ */}
          {/*    /!*  className='quint-body font-supportive color-white' *!/ */}
          {/*    /!*  style={{ alignSelf: 'flex-start', fontWeight: 600 }}> *!/ */}
          {/*    /!*  {data.button} *!/ */}
          {/*    /!* </LoadableQuintButton> *!/ */}
          {/*  </AniLink>} */}
        </div>
      </div>
    </>
  )
}

export default WhatWeDoPageSection4

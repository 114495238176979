import React from 'react'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { LoadableQuintButton } from '../LoadableComponents'
import { contactModalOpen } from '../ContactModal'

const WhatWeDoPageSection3 = ({ data }) => {
  const isMobile = useBreakpoint('sm', false)

  if (!data) {
    return ''
  }

  return (
    <>
      <div
        className='content-container' style={{ height: isMobile ? 'unset' : '100vh', minHeight: '800px' }}>
        <div style={{ width: '100%', maxWidth: 1200, display: 'flex', flexDirection: 'column' }}>
          {data.heading &&
            <div className='quint-heading-big-2 font-main color-primary' style={{ marginBottom: 32 }}>
              {data.heading}
            </div>}
          <div
            className='content-container-horizontal'
            style={{
              flex: 1,
              width: '100%',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              padding: 0
            }}>
            {isMobile &&
              <ul className='quint-list' style={{ minHeight: 500, marginBottom: 64 }}>
                {data.pillars && data.pillars.map((pillar, index) => {
                  return (
                    <div className='quint-subtitle font-main color-primary-dark quint-list-items' key={index}>
                      <div className='quint-list-bullet' />
                      <li style={{ textAlign: 'start' }}>
                        {pillar.content}
                      </li>
                    </div>
                  )
                })}
              </ul>}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {data.session_title &&
                <div className='quint-heading font-main color-primary' style={{ fontWeight: 'bold', marginBottom: 16 }}>
                  {data.session_title}
                </div>}
              {data.session_subtitle &&
                <div
                  className='quint-subtitle font-main color-primary-dark'
                  style={{
                    maxWidth: 516,
                    marginBottom: 32,
                    fontWeight: 'normal'
                  }}>
                  {data.session_subtitle}
                </div>}
              {data.session_button &&
                <LoadableQuintButton
                  id='3521420' boxShadow='true' borderColor='#25317B' rounded='contained' textColor='#ffffff' background='#25317B'
                  className='quint-button font-main text-align-center-on-small button-no-outline' width={isMobile ? '100%' : '220px'}
                  onClick={() => contactModalOpen()}>
                  {data.session_button}
                </LoadableQuintButton>}
            </div>
            {!isMobile &&
              <ul className='quint-list'>
                {data.pillars && data.pillars.map((pillar, index) => {
                  return (
                    <div className='quint-subtitle font-main color-primary-dark quint-list-items' key={index}>
                      <div className='quint-list-bullet' />
                      <li>
                        {pillar.content}
                      </li>
                    </div>
                  )
                })}
              </ul>}
          </div>
        </div>
      </div>
      <div
        className='content-container background-color-primary-light padding-0' style={{
          height: '16px',
        }} />
    </>
  )
}

export default WhatWeDoPageSection3

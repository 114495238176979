import React from 'react'
import WhatWeDoTemplate from '../components/WhatWeDoTemplate'
import { graphql } from 'gatsby'

function createSectionData(data) {
  const dataObject = {}
  Object.keys(data).forEach(value => {
    const sectionName = value.split(/_(.+)/)[0]
    const keyName = value.split(/_(.+)/)[1]
    if (dataObject[sectionName]) {
      dataObject[sectionName][keyName] = data[value]
    } else {
      dataObject[sectionName] = {}
      dataObject[sectionName][keyName] = data[value]
    }
  })
  return dataObject
}

const WhatWeDoPage = ({ data }) => {
  if (!data || !data.prismicWhatWeDoPage) {
    return ''
  }
  const { data: pageData } = data.prismicWhatWeDoPage
  const sectionData = createSectionData(pageData)

  return (
    <WhatWeDoTemplate
      meta_title={sectionData.meta ? sectionData.meta.title : null}
      meta_description={sectionData.meta ? sectionData.meta.description : null}
      meta_image={sectionData.meta && sectionData.meta.image ? sectionData.meta.image.url : null}
      whatWeDoSection1={sectionData.section1}
      whatWeDoDesignSection={sectionData.designsection}
      whatWeDoDevelopmentSection={sectionData.developmentsection}
      whatWeDoConsultingSection={sectionData.consultingsection}
      whatWeDoQuoteSection={sectionData.quotesection}
    />
  )
}

export default WhatWeDoPage

export const whatWeDoQuery = graphql`
  query WhatWeDoPage($id: String!) {
    prismicWhatWeDoPage(id: { eq: $id }) {
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        section1_heading
        section1_background_image {
          url
        }
        section1_medialeft {
          document {
            ... on PrismicMedia {
              id
              data {
                type
                speed
                file {
                  url
                }
              }
            }
          }
        }
        section1_mediaright {
          document {
            ... on PrismicMedia {
              id
              data {
                type
                speed
                file {
                  url
                }
              }
            }
          }
        }
        designsection_heading
        designsection_subtitle1
        designsection_subtitle2
        designsection_quote {
          document {
            ... on PrismicQuote {
              id
              data {
                person {
                  document {
                    ... on PrismicPeople {
                      id
                      data {
                        name
                        role
                        first_image {
                          url
                          alt
                        }
                      }
                    }
                  }
                }
                quote_content
              }
            }
          }
        }
        developmentsection_heading
        developmentsection_subtitle1
        developmentsection_subtitle2
        developmentsection_technologies {
          title
          icon {
            url
          }
        }
        developmentsection_quote {
          document {
            ... on PrismicQuote {
              id
              data {
                person {
                  document {
                    ... on PrismicPeople {
                      id
                      data {
                        name
                        role
                        first_image {
                          url
                          alt
                        }
                      }
                    }
                  }
                }
                quote_content
              }
            }
          }
        }
        consultingsection_heading
        consultingsection_session_title
        consultingsection_session_subtitle
        consultingsection_session_button
        consultingsection_pillars {
          content
        }
        quotesection_quote {
          document {
            ... on PrismicQuote {
              id
              data {
                person {
                  document {
                    ... on PrismicPeople {
                      id
                      data {
                        name
                        role
                        first_image {
                          url
                          alt
                        }
                      }
                    }
                  }
                }
                quote_content
              }
            }
          }
        }
        quotesection_button
      }
    }
  }
`

import React from 'react'
import WhatWeDoPageSection0 from '../WhatWeDoPageSection0'
import WhatWeDoPageSection1 from '../WhatWeDoPageSection1'
import WhatWeDoPageSection2 from '../WhatWeDoPageSection2'
import WhatWeDoPageSection3 from '../WhatWeDoPageSection3'
import WhatWeDoPageSection4 from '../WhatWeDoPageSection4'
import Seo from '../SEO'

const WhatWeDoTemplate = ({
  meta_title,
  meta_description,
  meta_image,
  whatWeDoSection1,
  whatWeDoDesignSection,
  whatWeDoDevelopmentSection,
  whatWeDoConsultingSection,
  whatWeDoQuoteSection,
}) => (
  <div>
    <Seo title={meta_title} description={meta_description} image={meta_image} />
    <WhatWeDoPageSection0 data={whatWeDoSection1} />
    <WhatWeDoPageSection1 data={whatWeDoDesignSection} />
    <WhatWeDoPageSection2 data={whatWeDoDevelopmentSection} />
    <WhatWeDoPageSection3 data={whatWeDoConsultingSection} />
    <WhatWeDoPageSection4 data={whatWeDoQuoteSection} />
  </div>
)

export default WhatWeDoTemplate
